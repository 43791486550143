import React from 'react';
import MultiInput from 'components/editor-blocks/MultiInput';

import '../styles/multi-email-builder-interface.scss';

/**
 * Show the interface for editing the settings or conditions of an email.
 * @param {*} param0
 * @returns
 */
const MultiEmailBuilderInterface = ({ dataModel, interfaceSetup, emailId, type }) => {
    return (
        <div className="multi-email-builder-interface">
            <MultiInput
                key={`edit_${emailId}`}
                data={{
                    blockModel: `${dataModel}.${emailId}.${type}`,
                    items: interfaceSetup,
                    sourceDataBlockModel: `${dataModel}.${emailId}.${type}.sourceData`
                }}
            />
        </div>
    );
};

export default MultiEmailBuilderInterface;
