import React, { useState, useEffect, createRef } from 'react';
import '../styles/render-overlay.scss';

/**
 * Calculate the style for the active block indicator.
 * @param {object} section
 * @returns
 */
const getStyle = (section) => {
    if (section) return { height: `${section.height}px`, top: `${section.y}px` };
    return { height: '10px', top: '0px' };
};

/**
 * Find a section in the layout
 * @param {array} layout
 * @param {string} uuid
 * @returns
 */
const getSection = (layout, uuid) => {
    return layout.find((s) => s.uuid === uuid);
};

/**
 * Find a section in the layout tht covers a y coordinate
 * @param {array} layout
 * @param {number} y
 * @returns
 */
const findSection = (layout, y) => {
    return layout.find((s) => {
        return y >= s.y && y < s.y + s.height;
    });
};

/**
 * Display the overlay that covers the iframe with the landing page in it.
 * @param {*} param0
 * @returns
 */
const LandingPageEditorRenderOverlay = ({ layout = [], activeBlock, onSetActive, onMoveToolbox }) => {
    const overlayTopRef = createRef();
    const overlayBottomRef = createRef();

    const [style, setStyle] = useState(getStyle(getSection(layout, activeBlock)));
    const [title, setTitle] = useState(false);
    const [titleY, setTitleY] = useState(0);
    const [bottomOverlayOffset, setBottomOverlayOffset] = useState(0);
    const [abTestGroups, setAbTestGroups] = useState(false);

    useEffect(() => {
        const section = getSection(layout, activeBlock);
        if (section) {
            onMoveToolbox(section.y);
            if (section.y && section.height) {
                //Set the bottom part of the overlay
                overlayTopRef.current.style.height = section.y + 'px';
                setBottomOverlayOffset(section.y + section.height);
                overlayBottomRef.current.style.top = section.y + section.height + 'px';
            }
        }
        setStyle(getStyle(section));
    }, [layout, activeBlock]);

    /**
     * Set the active block and move the toolbox next to it when the overlay is clicked.
     * @param {event} e
     * @param {boolean} isBottom
     */
    const clickOverlay = (e, isBottom) => {
        const clickedY = isBottom ? e.nativeEvent.offsetY + bottomOverlayOffset : e.nativeEvent.offsetY;
        const clickedSection = findSection(layout, clickedY);
        if (clickedSection) {
            onMoveToolbox(clickedSection.y);
            onSetActive(clickedSection.uuid);
        }
    };

    /**
     * Set the titlebar and it's content when hovering the overlay.
     * @param {event} e
     * @param {boolean} isBottom
     */
    const hoverOverlay = (e, isBottom) => {
        const hoverY = isBottom ? e.nativeEvent.offsetY + bottomOverlayOffset : e.nativeEvent.offsetY;
        const hoveredSection = findSection(layout, hoverY);
        if (hoveredSection) {
            setTitle(hoveredSection.workingTitle);
            setTitleY(hoveredSection.y);
            setAbTestGroups(hoveredSection.abTestGroups);
        }
    };

    return (
        <React.Fragment>
            {activeBlock && <div className="landingpage-editor-render-overlay__active" style={style} />}
            {title && (
                <div className="landingpage-editor-render-overlay__title" style={{ top: `${titleY}px` }}>
                    <div>{title}</div>
                    <div className="landingpage-editor-render-overlay__ab">
                        {abTestGroups &&
                            abTestGroups.split(',').map((g) => (
                                <div key={g} className="landingpage-editor-render-overlay__ab__test">
                                    {g}
                                </div>
                            ))}
                    </div>
                </div>
            )}
            <div
                className="landingpage-editor-render-overlay landingpage-editor-render-overlay--top"
                ref={overlayTopRef}
                onClick={(e) => clickOverlay(e, false)}
                onMouseMove={(e) => hoverOverlay(e, false)}
                onMouseOut={() => {
                    setTitle(false);
                    setAbTestGroups(false);
                }}
            />
            <div
                className="landingpage-editor-render-overlay landingpage-editor-render-overlay--bottom"
                ref={overlayBottomRef}
                onClick={(e) => clickOverlay(e, true)}
                onMouseMove={(e) => hoverOverlay(e, true)}
                onMouseOut={() => {
                    setTitle(false);
                    setAbTestGroups(false);
                }}
            />
        </React.Fragment>
    );
};

export default LandingPageEditorRenderOverlay;
