import '../styles/main.scss';

import { merge } from 'lodash';
import React from 'react';
import { withResources } from 'components/data/Resources';
import EditorData from 'components/editor-data/EditorData';
import DataHelpers from 'components/data/DataHelpers';
import DisplayPreviewSet from '../../DisplayPreviewSet';
import DisplayPreviewItem from '../../DisplayPreviewItem';

/**
 * DisplayPreviewHandler
 * The display preview renders a set or individual display ads in an overview.
 */
class DisplayPreviewHandler extends React.Component {
    /* Get an object per display ads by using the general settings and merging that with frame specific settings */
    getDisplayOutputSet = () => {
        const { editMode = 0, frameNr = 0, market = '', displayFormats, editModeBackground = false, value, sourceData } = this.props;

        const bannerData = [];

        this.props.formats.forEach((formatItem) => {
            let bannerSourceData;

            // Do translate and clone
            if (value) {
                bannerSourceData = DataHelpers.clone(value);
            } else {
                bannerSourceData = sourceData ? DataHelpers.clone(sourceData) : {};
            }

            // Integrate format specific settings
            if (bannerSourceData.overwrites && bannerSourceData.overwrites[formatItem]) {
                bannerSourceData = merge(bannerSourceData, bannerSourceData.overwrites[formatItem]);
            }

            this.doTranslate(bannerSourceData);

            bannerSourceData.format = '' + formatItem;
            bannerSourceData.editmode = editMode;
            bannerSourceData.frameNr = frameNr;
            bannerSourceData.market = market;

            if (displayFormats[formatItem]) {
                // Create format for DisplayPreview
                const formatData = {
                    title: displayFormats[formatItem].title,
                    type: displayFormats[formatItem].type,
                    width: displayFormats[formatItem].width,
                    height: displayFormats[formatItem].height,
                    format: formatItem,
                    url: this.props.url,
                    data: bannerSourceData,
                    editModeBackground: editModeBackground
                };

                bannerData.push(formatData);
            } else {
                console.log("Format doesn't exist");
            }
        });

        return bannerData;
    };

    /**
     * Translate the fields
     * @param {*} data
     */
    doTranslate(data) {
        if (data && data.multilanguage) {
            const language = EditorData.getLanguage();
            try {
                data.value = data[language].value;
            } catch (e) {
                console.log('Translation not found');
            }
        } else if (typeof data === 'object' && data !== null) {
            Object.keys(data).forEach((key) => {
                this.doTranslate(data[key]);
            });
        }
    }

    render() {
        const {
            displayType = 'set',
            hideFull,
            assetUpdateModel = '',
            urlNavigation = false,
            onPreviewSelected = () => {},
            selectedFormats = [],
            onChangeFormatBackground,
            fullWindow = false
        } = this.props;
        const outputSet = this.getDisplayOutputSet();

        return (
            <React.Fragment>
                {displayType === 'set' && (
                    <DisplayPreviewSet
                        hideFull={hideFull}
                        formats={outputSet}
                        selectedFormats={selectedFormats}
                        assetUpdateModel={assetUpdateModel}
                        urlNavigation={urlNavigation}
                        onPreviewSelected={onPreviewSelected}
                        onChangeFormatBackground={onChangeFormatBackground}
                        fullWindow={fullWindow}
                    />
                )}
                {displayType !== 'set' && <DisplayPreviewItem assetUpdateModel={assetUpdateModel} urlNavigation={urlNavigation} {...outputSet[0]} />}
            </React.Fragment>
        );
    }
}

export default withResources(DisplayPreviewHandler, [{ resource: 'displayFormats', path: 'resources/load' }]);
