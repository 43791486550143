import React from 'react';
import classNames from 'classnames';
import LanguageSelector from 'components/editor-base/LanguageSelector';
import { KLMOriginSwitch } from 'components/customers/KLM/KLMOriginSwitch';
import { VisualStackEditorAbTestTabbar } from 'components/ui-base/VisualStackEditor';

import '../styles/topbar.scss';

const EmailEditorTopbar = ({ abTesting, abTestGroupsShown, value, originSelector, className, onSetAbTestGroupsShown }) => {
    return (
        <div className={classNames('email-editor-topbar', className)}>
            <div className="email-editor-topbar__left">
                {abTesting && value.length > 0 && (
                    <VisualStackEditorAbTestTabbar
                        className="email-editor-topbar__ab-testing"
                        abTesting={abTesting}
                        abTestGroupsShown={abTestGroupsShown}
                        onSetAbTestGroupsShown={onSetAbTestGroupsShown}
                    />
                )}
            </div>
            <div className="email-editor-topbar__right">
                <div className="email-editor-topbar__language-container">
                    <LanguageSelector extended={true} />
                </div>
                {originSelector && (
                    <div className="email-editor-topbar__origin-selector">
                        <KLMOriginSwitch />
                    </div>
                )}
            </div>
        </div>
    );
};

export default EmailEditorTopbar;
