import React, { useState } from 'react';
import Dialog from 'components/ui-components/Dialog';
import MultiInput from 'components/editor-blocks/MultiInput';
import MultiEmailBuilderMetadata from './metadata';
import '../styles/multi-email-builder-add-dialog.scss';

/**
 * Show the dialog to add a new email.
 * @param {*} param0
 * @returns
 */
const MultiEmailBuilderAddDialog = ({ emailId, dataModel, settingsInterfaceSetup, onAddEmail, onCancelAddDialog }) => {
    const [emailName, setEmailName] = useState('');
    const [emailDesc, setEmailDesc] = useState('');

    return (
        <Dialog
            title="Add email"
            open={true}
            onClose={() => onCancelAddDialog(false)}
            onConfirm={() => onAddEmail(emailId, emailName, emailDesc)}
            confirmText="Add email"
            showCancel>
            <div className="multi-email-builder-add-dialog">
                <MultiEmailBuilderMetadata emailName={emailName} emailDesc={emailDesc} onSetEmailName={setEmailName} onSetEmailDesc={setEmailDesc} />
                <MultiInput
                    key={`edit_${emailId}`}
                    data={{
                        blockModel: `${dataModel}.${emailId}.settings`,
                        items: settingsInterfaceSetup,
                        sourceDataBlockModel: `${dataModel}.${emailId}.settings.sourceData`
                    }}
                />
            </div>
        </Dialog>
    );
};

export default MultiEmailBuilderAddDialog;
