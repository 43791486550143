import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import DOMPurify from 'dompurify';
import EmailHelpers from 'components/data/EmailHelpers';
import Illustration from 'components/ui-components-cape/Illustration';
import EmptyState from 'components/ui-components-cape/EmptyState';
import VisualStackEditorHelpers from 'components/data/VisualStackEditorHelpers';
import { withTemplates } from 'components/data/Templates';
import { EmailPreviewSnippetPreview } from '../../EmailPreview';
import { EmailEditorMobilePreview } from '../../EmailEditor';

import '../styles/multi-email-builder-preview.scss';

/**
 * Show a preview of a built email in either mobile or desktop view.
 * @param {*} param0
 * @returns
 */
const MultiEmailBuilderPreview = ({ mobilePreview, dataModel, emailId, groupKey, value, abTesting, abTestGroupsShown, language }) => {
    const [emailBase] = useState(EmailHelpers.getEmailBase(groupKey));
    const [items, setItems] = useState(EmailHelpers.outputEmail(`${dataModel}.${emailId}.blocks`, `${dataModel}.${emailId}.settings`, value));

    useEffect(() => {
        const items = EmailHelpers.outputEmail(`${dataModel}.${emailId}.blocks`, `${dataModel}.${emailId}.settings`, value);
        if (abTesting && abTestGroupsShown) {
            const filteredItems = items.filter((i) => !VisualStackEditorHelpers.getHidden(i.abTestGroups, abTesting, abTestGroupsShown));
            setItems(filteredItems);
        } else {
            setItems(items);
        }
    }, [emailId, abTesting, abTestGroupsShown]);

    useEffect(() => {
        setItems(EmailHelpers.outputEmail(`${dataModel}.${emailId}.blocks`, `${dataModel}.${emailId}.settings`, value));
    }, [language]);

    if (!items.length) {
        return (
            <div className="multi-email-builder-preview">
                <EmptyState
                    className="multi-email-builder-preview__empty-state"
                    illustration={<Illustration color="disabled" illustration="fallback" />}
                    primaryText="This email has no blocks yet!"
                    secondaryText="Edit design to add a block"
                />
            </div>
        );
    }

    return (
        <div className="multi-email-builder-preview">
            <EmailPreviewSnippetPreview settingsModel={`${dataModel}.${emailId}.settings`} conditionsModel={`${dataModel}.${emailId}.conditions`} />
            {mobilePreview ? (
                <div className={classNames('multi-email-builder-preview__container', 'multi-email-builder-preview__container--mobile')}>
                    <EmailEditorMobilePreview
                        editor={items}
                        emailBase={emailBase}
                        abTesting={abTesting}
                        abTestGroupsShown={abTestGroupsShown}
                        settingsModel={`${dataModel}.${emailId}.settings`}
                    />
                </div>
            ) : (
                <div className="multi-email-builder-preview__container">
                    {items.map((item) => (
                        <div
                            key={item.uuid}
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(item.html, { ADD_ATTR: ['target'] })
                            }}></div>
                    ))}
                </div>
            )}
        </div>
    );
};

MultiEmailBuilderPreview.defaultProps = {
    abTesting: false,
    abTestGroupsShown: false
};

export default withTemplates(MultiEmailBuilderPreview, [
    { type: 'emailBlock', fullData: true },
    { type: 'emailBase', fullData: true }
]);
