import '../styles/main.scss';

import PropTypes from 'prop-types';
import React from 'react';
import StackGrid from 'react-stack-grid';
import IconButton from '@mui/material/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import Switch from 'components/ui-components-v2/Switch';
import GridBlockFill from 'components/ui-components/GridBlockFill';
import DisplayPreviewItem from '../../DisplayPreviewItem';

/**
 * Class DisplayPreviewSet
 * Displays a set of all previews
 * This shows an overview based on the exact formats.
 * This can be in a grid view, or a list.
 * We also have pagination included, in case the load gets too high.
 */
class DisplayPreviewSet extends React.Component {
    static propTypes = {
        /** Url of the template. Optional, can also be set for each format */
        url: PropTypes.string,
        /** Data. Optional, can also be set for each format */
        data: PropTypes.object,
        /** Shows the formats that are available */
        formats: PropTypes.arrayOf(PropTypes.shape({})),
        /** Shows the formats in full size */
        showFullSize: PropTypes.bool,
        hideFull: PropTypes.bool,
        urlNavigation: PropTypes.bool
    };

    static defaultProps = {
        url: '',
        data: {},
        formats: [],
        showFullSize: true,
        hideFull: false,
        urlNavigation: false
    };

    constructor(props) {
        super(props);
        this.positionsResult = {};

        // Set the state
        this.state = {
            url: props.url,
            data: props.data,
            showFullSize: props.hideFull ? false : props.showFullSize,
            columnWidth: 282,
            gutterHeight: 24,
            gutterWidth: 24,
            page: 1
        };
    }

    /**
     * Show all items
     */
    showAll = () => {
        this.setState({ showFirstOnly: false }, () => {});
    };

    /**
     * Change full size value
     */
    handleSwitchChange = () => {
        this.setState({ showFullSize: !this.state.showFullSize });
    };

    /**
     * Set page to new value
     */
    setPage = (page) => {
        this.setState({ page: page });
    };

    render() {
        const {
            hideFull,
            standardView = 'stack',
            assetUpdateModel = '',
            urlNavigation,
            onPreviewSelected,
            selectedFormats,
            onChangeFormatBackground
        } = this.props;
        const { url, data, columnWidth, gutterHeight, gutterWidth, showFirstOnly, showFullSize, page } = this.state;

        // Formats
        let formats = this.props.formats;
        const pages = Math.ceil(this.props.formats.length / 10);
        if (pages > 1) {
            formats = this.props.formats.slice((page - 1) * 10, (page - 1) * 10 + 10);
        }

        // Do arrangement
        if (showFullSize && !showFirstOnly && (!this.formatsArranged || this.formatsArrangedPage != page)) {
            this.formatsArranged = 1;
            this.formatsArrangedPage = page;
            const formatsByObject = {};
            let maxWidth = 0;
            formats.forEach((item) => {
                formatsByObject[item.format] = item;
                if (item.width + 16 > maxWidth) {
                    maxWidth = item.width + 20;
                }
            });

            let windowWidth = window.innerWidth - 540;
            if (this.props.fullWindow) {
                windowWidth = window.innerWidth * 0.9;
            }

            const width = maxWidth > windowWidth ? maxWidth : windowWidth;
            this.positionsResult = GridBlockFill.returnPositions(width, formatsByObject, 16, 32);
        }

        return (
            <div className="display-preview-set">
                {!hideFull && formats.length > 1 && (
                    <div className="full-size">
                        <Switch checked={showFullSize} onChange={this.handleSwitchChange} />
                        Show full size
                    </div>
                )}

                {showFullSize && (
                    <div className="display-preview-set__full-grid" style={{ height: this.positionsResult.height }}>
                        {formats.map((format) => {
                            const { width, height, type, title } = format;
                            const frameNr = format.data.frameNr ? format.data.frameNr : 0;
                            const style = { width: format.width, margin: '0', marginBottom: 16 };
                            if (formats.length > 1) {
                                style.float = 'left';
                                style.marginLeft = 16;
                                style.marginRight = 16;
                            } else {
                                style.marginLeft = 'auto';
                                style.marginRight = 'auto';
                            }

                            return (
                                <div
                                    key={`${title}_${width}_${height}_${type}_${frameNr}`}
                                    className="display-preview-set__full-grid__item"
                                    style={{ left: this.positionsResult.positions[format.format].x, top: this.positionsResult.positions[format.format].y }}>
                                    <DisplayPreviewItem
                                        key={`${title}_${width}_${height}_${type}_${frameNr}_item`}
                                        url={format.url ? format.url : url}
                                        data={format.data ? format.data : data}
                                        width={format.width}
                                        height={format.height}
                                        originalWidth={format.width}
                                        originalHeight={format.height}
                                        editModeBackground={format.editModeBackground}
                                        type={type}
                                        title={title}
                                        format={format}
                                        style={style}
                                        isSelected={selectedFormats.includes(format.format)}
                                        urlNavigation={urlNavigation}
                                        onSelect={() => onPreviewSelected(format)}
                                        onChangeFormatBackground={(mode) => onChangeFormatBackground(format, mode)}
                                        assetUpdateModel={assetUpdateModel ? assetUpdateModel + '.overwrites.' + format.format : ''}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}

                {!showFullSize && standardView == 'stack' && (
                    <StackGrid columnWidth={columnWidth} gutterWidth={gutterWidth} gutterHeight={gutterHeight} duration={0}>
                        {formats.map((format) => {
                            const { width, height, type, title } = format;
                            const frameNr = format.data.frameNr ? format.data.frameNr : 0;

                            return (
                                <DisplayPreviewItem
                                    key={`${title}_${width}_${height}_${type}_${frameNr}`}
                                    url={format.url ? format.url : url}
                                    data={format.data ? format.data : data}
                                    width={columnWidth < format.width ? columnWidth : format.width}
                                    height={columnWidth < format.width ? (columnWidth / width) * height : (format.width / width) * height}
                                    originalWidth={format.width}
                                    originalHeight={format.height}
                                    editModeBackground={format.editModeBackground}
                                    type={type}
                                    title={title}
                                    format={format}
                                    isSelected={selectedFormats.includes(format.format)}
                                    urlNavigation={urlNavigation}
                                    onSelect={() => onPreviewSelected(format)}
                                    onChangeFormatBackground={(mode) => onChangeFormatBackground(format, mode)}
                                    assetUpdateModel={assetUpdateModel ? assetUpdateModel + '.overwrites.' + format.format : ''}
                                    style={{ width: columnWidth < format.width ? columnWidth : format.width, margin: '0 auto' }}
                                />
                            );
                        })}
                    </StackGrid>
                )}

                {pages > 1 && (
                    <div className="display-preview-set__pagination">
                        {page > 1 && (
                            <IconButton aria-label="Previous page" onClick={() => this.setPage(page - 1)} size="large">
                                <Icon>keyboard_arrow_left</Icon>
                            </IconButton>
                        )}

                        {page != pages && (
                            <IconButton aria-label="Next page" onClick={() => this.setPage(page + 1)} size="large">
                                <Icon>keyboard_arrow_right</Icon>
                            </IconButton>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default DisplayPreviewSet;
