import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import ToggleButtonGroup from 'components/ui-components-v2/ToggleGroup';
import ToggleButton from 'components/ui-components-v2/ToggleButton';

const EmailEditorResonsiveSwitch = ({ mobilePreview, onSetMobile, className }) => {
    return (
        <ToggleButtonGroup
            exclusive
            className={className}
            value={mobilePreview}
            onChange={(event, value) => {
                if (value === null) return;
                onSetMobile(value);
            }}>
            <ToggleButton value={true}>
                <Tooltip arrow title={'Mobile preview'}>
                    <Icon>phone_iphone</Icon>
                </Tooltip>
            </ToggleButton>
            <ToggleButton value={false}>
                <Tooltip arrow title={'Desktop preview and editing'}>
                    <Icon>desktop_windows</Icon>
                </Tooltip>
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default EmailEditorResonsiveSwitch;
