import React from 'react';
import { FieldSetCopy } from 'components/input/Copy';
import Divider from 'components/ui-components-v2/Divider';

const MultiEmailBuilderMetadata = ({ emailName, emailDesc, onSetEmailName, onSetEmailDesc }) => {
    return (
        <React.Fragment>
            <FieldSetCopy
                value={emailName}
                label="Email name"
                useValueObject={false}
                maxLength={25}
                onMutation={onSetEmailName}
                helperText="Email name will not apear when sending email"
            />
            <FieldSetCopy
                value={emailDesc}
                label="Email description"
                useValueObject={false}
                maxLength={40}
                onMutation={onSetEmailDesc}
                helperText="Email description will not apear when sending email"
            />
            <Divider />
        </React.Fragment>
    );
};

export default MultiEmailBuilderMetadata;
