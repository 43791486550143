import React from 'react';

export const IconReset = <g id="Icon-/-Reset" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g transform="translate(1.000000, 3.000000)" fill="#000000" id="Group-2">
        <g>
            <g id="close-24px-(1)" transform="translate(9.000000, 6.000000)" fillRule="nonzero">
                <polygon id="Path" points="6 0.604285714 5.39571429 0 3 2.39571429 0.604285714 0 0 0.604285714 2.39571429 3 0 5.39571429 0.604285714 6 3 3.60428571 5.39571429 6 6 5.39571429 3.60428571 3"></polygon>
            </g>
            <g id="restore-24px" fillRule="nonzero">
                <path d="M12,0 C7.03,0 3,4.03 3,9 L0,9 L3.89,12.89 L3.96,13.03 L8,9 L5,9 C5,5.13 8.13,2 12,2 C15.87,2 19,5.13 19,9 C19,12.87 15.87,16 12,16 C10.07,16 8.32,15.21 7.06,13.94 L5.64,15.36 C7.27,16.99 9.51,18 12,18 C16.97,18 21,13.97 21,9 C21,4.03 16.97,0 12,0 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</g>;
