import React, { useState, useEffect, useRef } from 'react';
import EditorData from 'components/editor-data/EditorData';
import '../styles/render.scss';

/**
 * Display the iframe in which the landing page is loaded.
 * @param {*} param0
 * @returns
 */
const LandingpagePreviewRender = ({
    value = [],
    sourceData,
    language,
    abTestGroupsShown,
    model,
    dataModel,
    settingsModel,
    iFrameUrl = 'http://localhost:3000',
    iFrameWidth = 1250,
    onLayoutChange = () => {}
}) => {
    const iFrameRef = useRef();
    const containerRef = useRef();
    const [iFrameReady, setIFrameReady] = useState(false);
    const [iFrameScale, setIFrameScale] = useState(1);
    const [iFrameHeight, setIFrameHeight] = useState(200);
    const layoutRef = useRef([]);

    useEffect(() => {
        /**
         * Handel a message received from the iframe.
         * @param {object} ev
         * @returns
         */
        const handleMessage = (ev) => {
            if (ev.origin === window.location.origin) {
                return false;
            }
            if (ev.data) {
                const { type, message } = ev.data;
                if (type === 'status' && message === 'ready') {
                    setIFrameReady(true);
                    sendContent({
                        testGroup: getTestGroup(abTestGroupsShown),
                        model,
                        dataModel,
                        settingsModel,
                        ...EditorData.get()
                    });
                }
                if (type === 'layout') {
                    handleLayout(message);
                }
            }
        };

        window.addEventListener('message', handleMessage);
        window.addEventListener('resize', handleResize);

        return () => {
            // unsubscribe message event
            window.removeEventListener('message', handleMessage);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    /**
     * Handel the resizing of the browser window. Update the scale and the layout.
     */
    const handleResize = () => {
        const scale = getIFrameScale();
        updateScaledLayout(layoutRef.current, scale);
        setIFrameScale(scale);
    };

    useEffect(() => {
        if (iFrameReady) {
            sendContent({
                testGroup: getTestGroup(abTestGroupsShown),
                model,
                dataModel,
                settingsModel,
                ...EditorData.get()
            });
        }
    }, [value, language, model, dataModel, settingsModel, sourceData, abTestGroupsShown]);

    useEffect(() => {
        const scale = getIFrameScale();
        updateScaledLayout(layoutRef.current, scale);
        setIFrameScale(scale);
    }, [value, language, sourceData, abTestGroupsShown, iFrameWidth, iFrameHeight]);

    /**
     * Send a message to the iFrame.
     * @param {object} data
     */
    const sendIFrameMessage = (data) => {
        if (iFrameRef && iFrameRef.current) {
            iFrameRef.current.contentWindow.postMessage(data, iFrameUrl);
        }
    };

    /**
     * Send the current content of the campaign to the iframe
     * @param {object} editorData
     */
    const sendContent = (editorData) => {
        // For KLM remove the origin, so the origin from 'editorData.editor.origin' is used in the template.
        if (editorData.origin) {
            delete editorData.origin;
        }
        sendIFrameMessage({
            type: 'editorData',
            editorData
        });
    };

    /**
     * Handel layout changes received from the iframe.
     * @param {array} layout
     */
    const handleLayout = (message) => {
        layoutRef.current = message.layout;
        const scale = getIFrameScale();
        updateScaledLayout(message.layout, scale);
        setIFrameScale(scale);
        setIFrameHeight(message.height);
    };

    /**
     * Transform the layout to a scaled version.
     * @param {array} layout
     * @param {number} scale
     * @returns
     */
    const scaleLayout = (layout, scale) => {
        const scaledLayout = layout.map((block) => {
            return {
                ...block,
                y: block.y * scale,
                height: block.height * scale
            };
        });
        return scaledLayout;
    };

    /**
     * Get the letter of the testgroup to show for communication to the iframe.
     * @param {array} abTestGroupsShown
     * @returns {string}
     */
    const getTestGroup = (abTestGroupsShown) => {
        if (!abTestGroupsShown) return false;
        if (abTestGroupsShown.length !== 1) return false;
        return abTestGroupsShown[0];
    };

    /**
     * Calculate and set the scaling that should be done on the iframe.
     */
    const getIFrameScale = () => {
        const currentContainerWidth = containerRef.current.clientWidth;
        const currentIFrameWidth = iFrameRef.current.clientWidth;
        if (currentIFrameWidth === 1250 && currentContainerWidth < currentIFrameWidth) {
            return currentContainerWidth / currentIFrameWidth;
        } else {
            return 1;
        }
    };

    /**
     * Return a scaled layout to the parent.
     * @param {layout} layout
     * @param {number} scale
     */
    const updateScaledLayout = (layout, scale) => {
        const scaledLayout = scaleLayout(layout, scale);
        onLayoutChange(scaledLayout);
    };

    return (
        <div className="landingpage-preview-render" ref={containerRef} style={{ height: iFrameHeight * iFrameScale }}>
            <iframe
                ref={iFrameRef}
                src={`${iFrameUrl}?inApp=1`}
                scrolling="no"
                frameBorder="0"
                style={{
                    width: iFrameWidth,
                    height: iFrameHeight,
                    transform: `scale(${iFrameScale})`,
                    transformOrigin: 'top left'
                }}
                className="landingpage-preview-render__frame"
            />
        </div>
    );
};

export default LandingpagePreviewRender;
