import '../styles/main.scss';

import { SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import DynamicAsset from 'components/assets/DynamicAsset';
import EditorData from 'components/editor-data/EditorData';
import Checkbox from 'components/ui-components-v2/Checkbox';
import { IconReset } from '../images/icon_reset';

class DisplayPreviewItem extends React.Component {
    static propTypes = {
        /** Width of the preview */
        width: PropTypes.number,
        /** Height of the preview */
        height: PropTypes.number,
        /** Title of the preview */
        title: PropTypes.string,
        /** URL of the preview */
        url: PropTypes.string,
        /** Data of the preview */
        data: PropTypes.object,
        /** Type of the preview */
        type: PropTypes.string,
        /** Background editable */
        editModeBackground: PropTypes.bool
    };

    static defaultProps = {
        width: 300,
        height: 250,
        title: 'Medium Rectangle 300x250px',
        url: '',
        data: {},
        type: 'Dynamic',
        canEdit: false
    };

    state = {
        iframeKey: 0
    };

    reloadDynamicAsset = () => {
        this.setState({ iframeKey: this.state.iframeKey + 1 });
    };

    resetOverwrites = () => {
        const { assetUpdateModel, format } = this.props;
        const blockModelActive = assetUpdateModel + '.frames.frame' + format.data.frameNr;
        EditorData.setModel(blockModelActive, {});
    };

    render() {
        const {
            url,
            data,
            width,
            height,
            originalWidth = this.props.width,
            originalHeight = this.props.height,
            title,
            type,
            style,
            assetUpdateModel,
            urlNavigation = false,
            onSelect,
            isSelected,
            onChangeFormatBackground,
            editModeBackground,
            canEdit
        } = this.props;
        const { iframeKey } = this.state;
        const canEditItem = data.editmode === 1 || canEdit;

        return (
            <div className={'display-preview-item' + (isSelected ? ' display-preview-item--selected' : '')} style={style}>
                <div className="display-preview-item__iframe-wrapper" style={{ width, height, background: 'lightgrey' }}>
                    <DynamicAsset
                        key={width + '_' + height + '_' + iframeKey}
                        width={width}
                        height={height}
                        frameWidth={originalWidth}
                        frameHeight={originalHeight}
                        type={'iframe'}
                        url={url}
                        data={data}
                        assetUpdateModel={assetUpdateModel}
                        urlNavigation={urlNavigation}
                    />
                </div>
                <div className={'display-preview-item__info' + (width < 400 ? ' display-preview-item__info--small' : '')}>
                    <div className="display-preview-item__info__block">
                        {canEditItem && (
                            <Tooltip title="Select this ad for overwrites">
                                <Checkbox className="action icon-reload" onClick={onSelect} checked={isSelected} />
                            </Tooltip>
                        )}
                        <div className="display-preview-item__info__block__title">{title}</div>
                    </div>
                    {type.toLowerCase() === 'dynamic' && (
                        <div className="display-preview-item__info__actions">
                            {editModeBackground && (
                                <Tooltip title="Crop the background image">
                                    <Icon
                                        className="display-preview-item__info__actions__action"
                                        fontSize="small"
                                        onClick={() => onChangeFormatBackground('cropper')}>
                                        crop
                                    </Icon>
                                </Tooltip>
                            )}
                            {canEditItem && (
                                <Tooltip title="Reset overwrites of this ad">
                                    <div className="display-preview-item__info__actions__action">
                                        <SvgIcon fontSize="default" onClick={this.resetOverwrites}>
                                            {IconReset}
                                        </SvgIcon>
                                    </div>
                                </Tooltip>
                            )}
                            <Tooltip title="Reload the ad">
                                <Icon className="display-preview-item__info__actions__action" fontSize="small" onClick={this.reloadDynamicAsset}>
                                    play_arrow
                                </Icon>
                            </Tooltip>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default DisplayPreviewItem;
