import React from 'react';
import Button from 'components/ui-components-v2/Button';
import RenderEmail from './render-email';
import SnippetPreview from './snippet-preview';
import EmailEditor from '../../EmailEditor';
import { EmailEditorResonsiveSwitch } from '../../EmailEditor';

import '../styles/main.scss';

class EmailPreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editorOpened: false,
            mobilePreview: false
        };
    }

    openEditor = () => {
        this.setState({
            editorOpened: true,
            mobilePreview: false
        });
    };

    closeEditor = () => {
        this.setState({
            editorOpened: false,
            mobilePreview: false
        });
    };

    render() {
        const { canEdit = false, value = [], editorData = {}, settingsModel, conditionsModel } = this.props;
        const { editorOpened, mobilePreview } = this.state;

        return (
            <div className="email-preview">
                {editorOpened && <EmailEditor additionalProps={{ onClose: this.closeEditor }} data={editorData} />}
                {!editorOpened && (
                    <React.Fragment>
                        {value.length > 0 && (
                            <div className="email-preview__actions">
                                <EmailEditorResonsiveSwitch
                                    onSetMobile={() => this.setState({ mobilePreview: !mobilePreview })}
                                    mobilePreview={mobilePreview}
                                    className="email-preview__responsive-switch"
                                />
                                {canEdit && (
                                    <Button onClick={this.openEditor} variant="contained" color="primary">
                                        Edit email
                                    </Button>
                                )}
                            </div>
                        )}
                        <SnippetPreview settingsModel={settingsModel} conditionsModel={conditionsModel} />
                        <RenderEmail {...this.props} onOpenEditor={this.openEditor} mobilePreview={mobilePreview} groupKey={editorData.groupKey} />
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default EmailPreview;
