import React from 'react';
import DOMPurify from 'dompurify';
import EditorData from 'components/editor-data/EditorData';

import '../styles/snippet-preview.scss';

class SnippetPreview extends React.Component {
    render() {
        const { settingsModel = 'email.settings', conditionsModel = 'email.conditions' } = this.props;

        let subject = EditorData.getValueFromModel(settingsModel + '.subject.' + EditorData.getLanguage() + '.value');
        let snippet = EditorData.getValueFromModel(settingsModel + '.snippet.' + EditorData.getLanguage() + '.value');

        // Get subject from subset
        const subsetActive = EditorData.getValueFromModel(settingsModel + '.generalSubset');
        if (subsetActive) {
            const subsetSubject = EditorData.getValueFromModel(settingsModel + '.subsets.' + subsetActive + '.subject.' + EditorData.getLanguage() + '.value');
            // If there is no subject in subset, use the original one.
            subject = subsetSubject ? subsetSubject : subject;
            const subsetSnippet = EditorData.getValueFromModel(settingsModel + '.subsets.' + subsetActive + '.snippet.' + EditorData.getLanguage() + '.value');
            // If there is no snippet in subset, use the original one.
            snippet = subsetSnippet ? subsetSnippet : snippet;
        }

        // Get conditions
        const origin = EditorData.get('origin');
        let conditions = EditorData.getValueFromModel(conditionsModel + '.' + origin);
        if (!conditions) conditions = EditorData.getValueFromModel(conditionsModel + '.GEN');
        if (conditions && conditions.multilanguage && conditions[EditorData.getLanguage()]) conditions = conditions[EditorData.getLanguage()].value;

        if (!subject && !snippet && !conditions) {
            return <React.Fragment />;
        }

        return (
            <div className="email-preview-snippet-preview">
                <div>
                    <span className="email-preview-snippet-preview__label">Subject:</span>
                    <span className="email-preview-snippet-preview__value">{subject}</span>
                </div>
                {snippet && (
                    <div>
                        <span className="email-preview-snippet-preview__label">Snippet:</span>
                        <span className="email-preview-snippet-preview__value">{snippet}</span>
                    </div>
                )}
                {conditions && (
                    <div>
                        <span className="email-preview-snippet-preview__label">Conditions:</span>
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(conditions) }} className="email-preview-snippet-preview__value" />
                    </div>
                )}
            </div>
        );
    }
}

export default SnippetPreview;
