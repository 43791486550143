import '../styles/main.scss';
import React from 'react';
import DisplayPreviewHandler from './handler';
import Button from 'components/ui-components-v2/Button';

export default class DisplayPreview extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editorOpened: false
        }
    }

    openEditor = () => {
        this.setState({
            editorOpened: true
        })
    }

    closeEditor = () => {
        this.setState({
            editorOpened: false
        })
    }

    render() {
        const { canEdit } = this.props;
        const { editorOpened } = this.state;

        return (
            <div className="display-preview">

                {!editorOpened && (
                    <React.Fragment>
                        {canEdit && (
                            <div className="edit-button">
                                <Button onClick={this.openEditor} variant="contained" color="primary" >Edit display ads</Button>
                            </div>
                        )}
                        <DisplayPreviewHandler {...this.props} />
                    </React.Fragment>
                )}

            </div>
        )
    }

}




