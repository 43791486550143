import EditorDynamicDataWrapper from 'components/editor-data/EditorDynamicDataWrapper';
import DisplayPreviewHandler from './components/handler';
import DisplayPreview from './components/index';
import DisplayPreviewClean from './components/clean';

const DisplayPreviewDynamicData = EditorDynamicDataWrapper(DisplayPreview);
const DisplayPreviewCleanDynamicData = EditorDynamicDataWrapper(DisplayPreviewClean);

export default DisplayPreviewDynamicData;
export { DisplayPreviewDynamicData, DisplayPreview, DisplayPreviewHandler, DisplayPreviewCleanDynamicData };
