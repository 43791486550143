import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import ToggleButtonGroup from 'components/ui-components-v2/ToggleGroup';
import ToggleButton from 'components/ui-components-v2/ToggleButton';

/**
 * Display the switch tho switch the preview to mobile or desktop.
 * @param {*} param0
 * @returns
 */
const LandingpagePreviewViewSwitch = ({ onSetViewWidth, viewWidth }) => {
    return (
        <ToggleButtonGroup
            exclusive
            value={viewWidth}
            onChange={(event, value) => {
                if (value === null) return;
                onSetViewWidth(value);
            }}>
            <ToggleButton value={375}>
                <Icon>smartphone</Icon>
            </ToggleButton>
            <ToggleButton value={1250}>
                <Icon>desktop_windows</Icon>
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default LandingpagePreviewViewSwitch;
