import React, { useState, useEffect, useRef } from 'react';
import { FieldSetRepeater } from 'components/input/Repeater';
import Divider from 'components/ui-components-v2/Divider';
import Translation from 'components/data/Translation';
import EditorData from 'components/editor-data/EditorData';

/**
 * Get the input props to pass to the date picker.
 * @returns
 */
const getInputProps = () => {
    const inputProps = {
        canBeEmpty: true
    };

    // If the whole campaign has on= and offline dates, set these as min and max for the email drop dates.
    const campaignPlanning = EditorData.getValueFromModel('settings.planning');
    if (campaignPlanning) {
        const { online, offline } = campaignPlanning;
        if (online) inputProps.minDate = online;
        if (offline) inputProps.maxDate = offline;
    }
    return inputProps;
};

/**
 * Enter the planning (drop dates) for an email.
 * @param {*} param0
 * @returns
 */
const MultiEmailBuilderPlanning = ({ dataModel, emailId, planningMax, onRebuildPlanning }) => {
    const [value, setValue] = useState(EditorData.getValueFromModel(`${dataModel}.${emailId}.planning`));

    const inputProps = useRef(getInputProps());

    const handleChange = (value) => {
        EditorData.setModel(`${dataModel}.${emailId}.planning`, value);
        onRebuildPlanning();
    };

    useEffect(() => {
        setValue(EditorData.getValueFromModel(`${dataModel}.${emailId}.planning`) || [null]);
    }, [emailId]);

    return (
        <React.Fragment>
            <FieldSetRepeater
                key={`multi-email-builder-planning-datepicker-${emailId}`}
                type="repeater"
                value={value}
                inputType="date"
                label={Translation.get('labels.planning', 'common')}
                max={planningMax}
                onMutation={handleChange}
                inputProps={inputProps.current}
                helperText={Translation.get('email.helpertext.dropdates', 'editor', { count: planningMax })}
            />
            <Divider />
        </React.Fragment>
    );
};

export default MultiEmailBuilderPlanning;
