import React, { useState, useEffect } from 'react';
import Comments from 'components/social/Comments';
import DataHelpers from 'components/data/DataHelpers';
import EditorData from 'components/editor-data/EditorData';
import Sidebar from 'components/ui-base/Sidebar';
import Icon from 'components/ui-components-v2/Icon';
import MultiEmailBuilderDetail from './detail';
import MultiEmailBuilderInterface from './interface';
import MultiEmailBuilderPreview from './preview';
import MultiEmailBuilderMetadata from './metadata';
import MultiEmailBuilderPlanning from './planning';
import store from '../../../../../store';

import '../styles/multi-email-builder-overview.scss';

const getEmailValue = (value, activeEmailId) => {
    if (!Array.isArray(value)) return null;

    const emailValue = value.find((v) => v.uuid === activeEmailId);
    return emailValue ? emailValue : null;
};

/**
 * Show the overiew with sidebar of all available emails in the builder.
 * @param {*} param0
 * @returns
 */
const MultiEmailBuilderOverview = ({
    value,
    dataModel,
    activeEmailId,
    settingsInterfaceSetup,
    conditionsInterfaceSetup,
    groupKey,
    canEdit,
    review,
    planning,
    planningMax,
    abTesting,
    abTestGroupsShown,
    onSetActiveEmailId,
    onSetEditorOpen,
    onSetAddDialogOpen,
    onSetRemoveDialogOpen,
    onEditMetadata,
    onCopyEmail,
    onRebuildPlanning,
    language
}) => {
    const [emailValue, setEmailValue] = useState(getEmailValue(value, activeEmailId));
    const [mobilePreview, setMobilePreview] = useState(false);
    const [view, setView] = useState('design');

    useEffect(() => {
        setEmailValue(getEmailValue(value, activeEmailId));
    }, [value, activeEmailId]);

    return (
        <Sidebar
            listTitle={`Email (${value.length})`}
            list={value.map((i) => ({
                id: i.uuid,
                title: i.name,
                subTitle: i.description,
                typeIcon: <Icon>email</Icon>
            }))}
            activeItem={activeEmailId}
            onSelect={onSetActiveEmailId}
            showSingle
            onClickMainButton={canEdit ? () => onSetAddDialogOpen(true) : undefined}
            mainButtonCopy="Add email"
            mainButtonIcon={<Icon>add</Icon>}>
            {emailValue && (
                <MultiEmailBuilderDetail
                    name={emailValue.name}
                    description={emailValue.description}
                    activeEmailId={activeEmailId}
                    view={view}
                    mobilePreview={mobilePreview}
                    hasConditionsInterface={conditionsInterfaceSetup && conditionsInterfaceSetup.length}
                    canEdit={canEdit}
                    review={review}
                    onSetEditorOpen={onSetEditorOpen}
                    onSetRemoveDialogOpen={onSetRemoveDialogOpen}
                    onSetView={setView}
                    onSetMobilePreview={setMobilePreview}
                    onCopyEmail={onCopyEmail}>
                    {view === 'design' && (
                        <MultiEmailBuilderPreview
                            value={DataHelpers.getValue(store.getState().editor.data, `${dataModel}.${activeEmailId}.setup`)}
                            dataModel={dataModel}
                            emailId={activeEmailId}
                            mobilePreview={mobilePreview}
                            groupKey={groupKey}
                            abTesting={abTesting}
                            abTestGroupsShown={abTestGroupsShown}
                            language={language}
                        />
                    )}
                    {view === 'settings' && (
                        <React.Fragment>
                            <div className="multi-email-builder-overview__metadata">
                                <MultiEmailBuilderMetadata
                                    emailName={emailValue.name}
                                    emailDesc={emailValue.description}
                                    onSetEmailName={(name) => onEditMetadata({ name })}
                                    onSetEmailDesc={(description) => onEditMetadata({ description })}
                                />
                                {planning && (
                                    <MultiEmailBuilderPlanning
                                        key={`multi-email-builder-planning-${activeEmailId}`}
                                        emailSetup={value}
                                        dataModel={dataModel}
                                        emailId={activeEmailId}
                                        planningMax={planningMax}
                                        onRebuildPlanning={onRebuildPlanning}
                                    />
                                )}
                            </div>
                            <MultiEmailBuilderInterface dataModel={dataModel} interfaceSetup={settingsInterfaceSetup} emailId={activeEmailId} type={view} />
                        </React.Fragment>
                    )}
                    {view === 'conditions' && (
                        <MultiEmailBuilderInterface dataModel={dataModel} interfaceSetup={conditionsInterfaceSetup} emailId={activeEmailId} type={view} />
                    )}
                    {view === 'comments' && (
                        <div className="multi-email-builder-overview__comments">
                            <Comments
                                key={`multiEmailBuilder.${activeEmailId}`}
                                campaignId={EditorData.getId()}
                                entity={`multiEmailBuilder.${activeEmailId}`}
                            />
                        </div>
                    )}
                </MultiEmailBuilderDetail>
            )}
        </Sidebar>
    );
};

MultiEmailBuilderOverview.defaultProps = {
    abTesting: false,
    abTestGroupsShown: false
};

export default MultiEmailBuilderOverview;
