import React from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { flow } from 'lodash';
import DOMPurify from 'dompurify';
import '../styles/content-block.scss';

const Types = {
    ITEM: 'block'
};

const sourceSpec = {
    beginDrag(props, monitor, component) {
        return { uuid: props.uuid };
    },
    canDrag(props) {
        return props.canMove;
    }
};

const targetSpec = {
    hover(props, monitor, component) {
        component.props.onHoverBlock(props.uuid, monitor.getClientOffset());
    }
};

function sourceCollect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    };
}

const targetCollect = (connect, monitor) => {
    return {
        connectDropTarget: connect.dropTarget()
    };
};

/**
 * EmailEditorContentBlock
 * This is an individual block in the email. E.g. an article, image etc.
 */
class EmailEditorContentBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hovered: false,
            hidden: false
        };
    }

    static propTypes = {
        uuid: PropTypes.string
    };

    /**
     * Move toolbox next to this block if it is active.
     */
    componentDidMount() {
        if (this.props.uuid == this.props.activeBlock) {
            this.props.onMoveToolbox();
        }
    }

    /**
     * Monitor whether this block is being dragged and call function the register it as such.
     * @param {object} prevProps
     */
    componentDidUpdate(prevProps) {
        const { uuid, isDragging } = this.props;

        if (isDragging !== prevProps.isDragging) {
            if (isDragging) {
                this.props.onStartDragBlock(uuid);
            } else {
                this.props.onEndDragBlock();
            }
        }
    }

    /**
     * Renders the components
     * @returns {*}
     */
    render() {
        const {
            uuid,
            html,
            workingTitle,
            sourceBlockUuid,
            activeBlock,
            onSetActive,
            connectDragSource,
            connectDropTarget,
            canMove,
            pinnedToTop,
            pinnedToBottom,
            abTesting,
            abTestGroups
        } = this.props;
        const { hovered } = this.state;

        return connectDropTarget(
            connectDragSource(
                <div
                    id={uuid}
                    className={classNames('email-editor-content-block', {
                        'email-editor-content-block--dragging': uuid === sourceBlockUuid,
                        'email-editor-content-block--nodrag': !canMove || pinnedToTop || pinnedToBottom
                    })}
                    onClick={() => onSetActive(uuid)}
                    onMouseOver={() => this.setState({ hovered: true })}
                    onMouseLeave={() => this.setState({ hovered: false })}>
                    <Box
                        display="flex"
                        alignItems="center"
                        boxShadow={1}
                        justifyContent="space-between"
                        className={classNames('email-editor-content-block__titlebar', {
                            'email-editor-content-block__titlebar--active': hovered && !sourceBlockUuid
                        })}>
                        <Typography variant="h4" className="email-editor-content-block__titlebar__title">
                            {workingTitle}
                        </Typography>
                        {abTesting && abTestGroups && abTestGroups.split && (
                            <Box display="flex" alignItems="center">
                                {abTestGroups
                                    .split(',')
                                    .sort()
                                    .map((v) => (
                                        <div key={v} className="email-editor-content-block__titlebar__test">
                                            {v}
                                        </div>
                                    ))}
                            </Box>
                        )}
                    </Box>
                    <div
                        className={classNames('email-editor-content-block__border', {
                            'email-editor-content-block__border--active': activeBlock === uuid
                        })}></div>
                    <div
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html, { ADD_ATTR: ['target'] }) }}
                        className="email-editor-content-block__content"></div>
                </div>
            )
        );
    }
}

export default flow([DragSource(Types.ITEM, sourceSpec, sourceCollect), DropTarget(Types.ITEM, targetSpec, targetCollect)])(EmailEditorContentBlock);
