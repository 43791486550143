import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';
import EditorData from 'components/editor-data/EditorData';
import Illustration from 'components/ui-components-cape/Illustration';
import EmptyState from 'components/ui-components-cape/EmptyState';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import MultiEmailBuilderOverview from './overview';
import MultiEmailBuilderAddDialog from './add-dialog';
import EmailEditor from '../../EmailEditor';

/**
 * Show the Multi email builder.
 * @param {*} param0
 * @returns
 */
const MultiEmailBuilder = ({
    value,
    dataModel,
    editorData,
    canEdit,
    review,
    settingsInterfaceSetup,
    conditionsInterfaceSetup,
    planning,
    planningMax,
    abTesting,
    abTestGroupsShown,
    onMutation,
    onSetActiveEmailId,
    language
}) => {
    const [newEmailId, setNewEmailId] = useState(null);
    const [activeEmailId, setActiveEmailId] = useState(value?.length ? value[0].uuid : null);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [editorOpen, setEditorOpen] = useState(false);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

    useEffect(() => {
        if (onSetActiveEmailId) {
            onSetActiveEmailId(activeEmailId);
        }
    }, [activeEmailId]);

    useEffect(() => {
        if (planning) rebuildPlanning();
    }, [value]);

    /**
     * Open the dialog to add a new email.
     */
    const openAddDialog = () => {
        setNewEmailId(`email_${uuidv4().replace(/-/g, '')}`);
        setAddDialogOpen(true);
    };

    /**
     * Cancel adding a new email.
     */
    const cancelAddDialog = () => {
        EditorData.removeItem(`${dataModel}.${newEmailId}`);
        setNewEmailId(null);
        setAddDialogOpen(false);
    };

    /**
     * Add a new email
     * @param {string} uuid
     * @param {string} name
     */
    const addEmail = (uuid, name, description = '') => {
        if (Array.isArray(value)) {
            value.push({ uuid, name, description });
        } else {
            value = [{ uuid, name, description }];
        }
        setAddDialogOpen(false);
        onMutation(value);
        setActiveEmailId(uuid);
    };

    /**
     * Remove an email.
     * @param {string} emailId
     */
    const removeEmail = (emailId) => {
        const newValue = value.filter((v) => v.uuid !== emailId);
        EditorData.removeItem(`${dataModel}.${emailId}`);
        onMutation(newValue);
        setActiveEmailId(newValue.length ? newValue[0].uuid : null);
        setRemoveDialogOpen(false);
    };

    /**
     * Copy an email.
     */
    const copyEmail = () => {
        const sourceEmailValue = value.find((v) => v.uuid === activeEmailId);

        if (sourceEmailValue) {
            const uuid = `email_${uuidv4().replace(/-/g, '')}`;
            const newData = cloneDeep(EditorData.getValueFromModel(`${dataModel}.${activeEmailId}`));
            EditorData.setModel(`${dataModel}.${uuid}`, newData);
            value.push({ uuid, name: `${sourceEmailValue.name} - copy` });
            setActiveEmailId(uuid);
        }
    };

    /**
     * Edit the email name.
     * @param {object} data
     */
    const editMetadata = (data) => {
        const newValue = value.map((v) => {
            if (v.uuid === activeEmailId) {
                return {
                    ...v,
                    ...data
                };
            } else return v;
        });
        onMutation(newValue);
    };

    const rebuildPlanning = () => {
        const planning = {};
        if (value) {
            value.forEach((email, emailIndex) => {
                const emailPlanning = EditorData.getValueFromModel(`${dataModel}.${email.uuid}.planning`);
                if (emailPlanning && Array.isArray(emailPlanning)) {
                    emailPlanning.forEach((date, dateIndex) => {
                        if (date) {
                            planning[`Email-${emailIndex + 1}-date-${dateIndex + 1}`] = {
                                date: moment(date).format('YYYY-MM-DD')
                            };
                        }
                    });
                }
            });
            EditorData.setModel('settings.planning.channels.email.subplanning', planning);
        }
    };

    return (
        <React.Fragment>
            {editorOpen ? (
                <EmailEditor
                    additionalProps={{ onClose: () => setEditorOpen(false) }}
                    data={{
                        ...editorData,
                        model: `${dataModel}.${activeEmailId}.setup`,
                        dataModel: `${dataModel}.${activeEmailId}.blocks`,
                        sourceDataModel: `${dataModel}.${activeEmailId}.blocks`,
                        settingsModel: `${dataModel}.${activeEmailId}.settings`
                    }}
                />
            ) : (
                <React.Fragment>
                    {!value || !value.length ? (
                        <EmptyState
                            className="multi-email-builder-interface__empty-state "
                            illustration={<Illustration illustration="fallback" />}
                            primaryText="You haven't created any emails yet!"
                            secondaryText="Ready to create?"
                            primaryButton={{ label: 'Create email', onClick: () => openAddDialog() }}
                        />
                    ) : (
                        <MultiEmailBuilderOverview
                            value={value}
                            dataModel={dataModel}
                            settingsInterfaceSetup={settingsInterfaceSetup}
                            conditionsInterfaceSetup={conditionsInterfaceSetup}
                            activeEmailId={activeEmailId}
                            groupKey={editorData && editorData.groupKey}
                            canEdit={canEdit}
                            review={review}
                            planning={planning}
                            planningMax={planningMax}
                            abTesting={abTesting}
                            abTestGroupsShown={abTestGroupsShown}
                            onSetActiveEmailId={setActiveEmailId}
                            onSetEditorOpen={setEditorOpen}
                            onSetAddDialogOpen={openAddDialog}
                            onSetRemoveDialogOpen={setRemoveDialogOpen}
                            onEditMetadata={editMetadata}
                            onCopyEmail={copyEmail}
                            onRebuildPlanning={rebuildPlanning}
                            language={language}
                        />
                    )}
                    {addDialogOpen && (
                        <MultiEmailBuilderAddDialog
                            dataModel={dataModel}
                            emailId={newEmailId}
                            onCancelAddDialog={cancelAddDialog}
                            onAddEmail={addEmail}
                            settingsInterfaceSetup={settingsInterfaceSetup}
                        />
                    )}
                    {removeDialogOpen && (
                        <ConfirmDialog
                            open={true}
                            title="Remove this email?"
                            description={`Are you sure you want to remove email '${value.find((v) => v.uuid === activeEmailId).name}'?`}
                            onConfirm={() => removeEmail(activeEmailId)}
                            onClose={() => setRemoveDialogOpen(false)}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default MultiEmailBuilder;
