import React, { useState, useEffect, useRef } from 'react';
import DataHelpers from 'components/data/DataHelpers';
import VisualStackEditorHelpers from 'components/data/VisualStackEditorHelpers';
import CustomerHelperLoader from 'components/data/CustomerHelperLoader';
import store from '../../../../../store';

/**
 * Clean up the body of the base HTML for a clean preview.
 * @param {object} emailBase
 * @returns clean base HTML as string.
 */
const cleanBaseHTML = (emailBase) => {
    let baseHTML = emailBase.html;
    // We want to cleanup the body of the base o the preview is nicce and clean.
    if (!baseHTML) {
        return 'Emailbase missing';
    }

    try {
        const baseHead = baseHTML.substring(baseHTML.indexOf('<head>') + 6, baseHTML.lastIndexOf('</head>'));
        const parser = new DOMParser();
        const baseDOM = parser.parseFromString(emailBase.html, 'text/html');
        const body = baseDOM.getElementsByTagName('body');
        const head = baseDOM.getElementsByTagName('head');
        body[0].innerHTML = '${main}'; // eslint-disable-line

        if (baseHead) {
            head[0].innerHTML = baseHead; // eslint-disable-line
        }

        baseHTML = baseDOM.documentElement.innerHTML;
    } catch (e) {
        console.log('Error cleaning email base ', e);
    }

    return baseHTML;
};

/**
 * Build the HTML to show in the preview.
 * @param {array} value
 * @param {*} emailBase
 * @param {*} abTesting
 * @param {*} abTestGroupsShown
 * @returns email as html string
 */
const getHtml = (value, baseHTML, abTesting, abTestGroupsShown, settingsModel = 'email.settings') => {
    // Get variables
    let campaign = store.getState().editor.data; // eslint-disable-line
    let editor = DataHelpers.clone(store.getState().editor); // eslint-disable-line
    let market = editor.market; // eslint-disable-line
    let language = editor.language; // eslint-disable-line
    let origin = editor.origin; // eslint-disable-line
    let customerHelper = new CustomerHelperLoader.helper(); // eslint-disable-line

    // Get subject
    function getSubject() {
        // eslint-disable-line
        return getElementCopy('subject');
    }

    // Get snippet
    function getSnippet() {
        // eslint-disable-line
        return getElementCopy('snippet');
    }

    // Get element with copy
    function getElementCopy(path) {
        // eslint-disable-line
        let element = DataHelpers.getValue(campaign, settingsModel + '.' + path + '.' + language + '.value');
        // Get subject from subset
        const subsetActive = DataHelpers.getValue(campaign, settingsModel + '.generalSubset');
        if (subsetActive) {
            const subsetElement = DataHelpers.getValue(campaign, settingsModel + '.subsets.' + subsetActive + '.' + path + '.' + language + '.value');
            // If there is no element in subset, use the original one.
            element = subsetElement ? subsetElement : element;
        }

        if (!element) {
            return '';
        }

        return element;
    }

    // Get element
    function getElement(path) {
        // eslint-disable-line
        const element = DataHelpers.getValue(campaign, settingsModel + '.' + path + '.' + language + '.value');
        return element;
    }

    // Get general copy
    function getGeneralCopy(path) {
        // eslint-disable-line
        try {
            const value = DataHelpers.getValue(campaign, path + '.' + language + '.value');
            return value;
        } catch (e) {
            return '';
        }
    }

    // Wrap base
    let main = '';
    value.forEach((block) => {
        const hidden = VisualStackEditorHelpers.getHidden(block.abTestGroups, abTesting, abTestGroupsShown);
        if (!hidden) main = main + block.html;
    });

    // Combine the baseHTML with the rendered blocks in base.
    let outputHTML = baseHTML;
    try {
        outputHTML = eval('`' + baseHTML + '`');
    } catch (e) {
        // eslint-disable-line
        console.log('Error rendering email block ', e);
    }

    return outputHTML;
};

const EmailEditorMobilePreview = ({ editor, emailBase, abTesting, abTestGroupsShown, settingsModel }) => {
    const ref = useRef();
    const [iFrameHeight, setIFrameHeight] = useState();
    const [baseHTML] = useState(cleanBaseHTML(emailBase));

    // Set the iframe height to fit the page. When content is loaded is will be resized to fit that content.
    useEffect(() => {
        if (ref && ref.current) {
            const { top } = ref.current.getBoundingClientRect();
            setIFrameHeight(window.innerHeight - top + 'px');
        } else {
            setIFrameHeight(window.innerHeight + 'px');
        }
    }, []);

    // Resize iframe height to fit the content
    const resizeIFrame = () => {
        setIFrameHeight(ref.current.contentWindow.document.body.scrollHeight + 'px');
    };

    return (
        <iframe
            ref={ref}
            srcDoc={getHtml(editor, baseHTML, abTesting, abTestGroupsShown, settingsModel)}
            title="mobile"
            width="375px"
            height={iFrameHeight}
            scrolling="no"
            frameBorder="0"
            onLoad={() => resizeIFrame()}
        />
    );
};

export default EmailEditorMobilePreview;
