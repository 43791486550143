import React, { useCallback, useEffect, useRef, useState } from 'react';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import GetAppIcon from '@mui/icons-material/GetApp';
import UpdateIcon from '@mui/icons-material/Update';
import Button from 'components/ui-components-v2/Button';
import Tooltip from 'components/ui-components-v2/Tooltip';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import EditorData from 'components/editor-data/EditorData';
import TemplateHelpers from 'components/data/TemplateHelpers';
import TemplateDesignerService from 'components/template-designer/services/template-designer.service';
import store from '../../../../../store';
import '../styles/main.scss';

const DynamicPDF = (props) => {
    const [previewData, setPreviewData] = useState({});
    const [renderStatus, setRenderSatus] = useState(0);
    const interval = useRef(null);

    /**
     * On mount, check if there is already and rendered preview.
     */
    useEffect(() => {
        const newPreviewData = (() => {
            if (props.previewDataModel) {
                return EditorData.getValueFromModel(props.previewDataModel);
            } else if (props.data.previewData) {
                return props.data.previewData;
            } else if (props.data.value) {
                return props.data.value;
            }
            return {};
        })();

        if (newPreviewData && newPreviewData.loading) {
            startRender();
        } else if (newPreviewData && !newPreviewData.loading && newPreviewData.previewUrl) {
            setRenderSatus(newPreviewData.status);
            setPreviewData((prevState) => ({ ...prevState, previewUrl: newPreviewData.previewUrl }));
        }

        return () => {
            interval.current && clearInterval(interval.current);
        };
    }, []);

    /**
     * Start the render of the preview.
     * Create an interval to check on the status.
     */
    const startRender = useCallback(async () => {
        setPreviewData((prevState) => ({ ...prevState, loading: true }));
        savePreviewData(undefined, true);
        setRenderSatus(0);

        const data = TemplateHelpers.parseTdInDesign(
            {},
            {
                blockData: props.data,
                format: props.data.format,
                template: {
                    type: props.template.type,
                    identifier: props.template.templateSetup.templateSetup.adobe.identifier || props.template.identifier,
                    downloadUrl: props.template.templateSetup.templateSetup.adobe.downloadUrl,
                    layers: props.template.templateSetup.layers,
                    interfaceSetup: props.template.interfaceSetup,
                    pages: template.templateSetup.pages,
                    formats: template.templateSetup.formats
                },
                preview: true
            },
            store.getState().editor.language,
            true
        );

        try {
            const token = await TemplateDesignerService.getInDesignToken();
            const response = await TemplateDesignerService.startPreviewRender(token, data);

            response.loading = true;
            interval.current && clearInterval(interval.current);
            interval.current = setInterval(() => checkRender(response.uuid, token), 2000);
            checkRender(response.uuid, token);
        } catch (error) {
            sessionStorage.removeItem(TemplateDesignerService.INDESIGN_TOKEN);
            interval.current && clearInterval(interval.current);
            setPreviewData((prevState) => ({ ...prevState, previewUrl: null, loading: false }));
            SnackbarUtils.error(error.toString());
        }
    }, [interval.current, props.data, props.template]);

    /**
     * Check the status of the render.
     */
    const checkRender = async (uuid, token) => {
        try {
            const response = await TemplateDesignerService.checkPreviewRender(uuid, token);

            if (!response) {
                throw 'Error in getting status';
            }

            // Render error.
            if (response.status === -1) {
                throw response;
            }

            // Render complete.
            if (response.status === 2) {
                clearInterval(interval.current);
                setPreviewData((prevState) => ({ ...prevState, previewUrl: response.previewUrl, loading: false }));
                savePreviewData(response.previewUrl, false);
                return setRenderSatus(2);
            }

            setRenderSatus(response.status);
        } catch (error) {
            sessionStorage.removeItem(TemplateDesignerService.INDESIGN_TOKEN);
            clearInterval(interval.current);
            setPreviewData((prevState) => ({ ...prevState, previewUrl: null, loading: false }));
            savePreviewData(undefined, false);
            setRenderSatus(-1);
            SnackbarUtils.error(error.toString());
        }
    };

    /**
     * Save the preview data in Redux.
     */
    const savePreviewData = (previewData, loading = false) => {
        const { previewDataModel, onMutation } = props;
        const newData = { previewUrl: previewData, loading };

        if (previewDataModel) {
            // In case we write to a model
            EditorData.setModel(previewDataModel, newData);
        } else if (onMutation) {
            // In case we use onMutation.
            onMutation(newData);
        }
    };

    /**
     * Download the preview by opening it in a new tab.
     */
    const onDownload = () => {
        window.open(previewData.previewUrl);
    };

    const { className, style, displayWidth, displayHeight, width, height, format, template } = props;

    return (
        <div className="dynamic-pdf">
            <div className={'dynamic-pdf__container dynamic-pdf__container--' + format} style={{ width: displayWidth, height: displayHeight }}>
                {!previewData.loading && previewData.previewUrl && (
                    <img className={className} style={style} width={width} height={height} src={previewData.previewUrl} />
                )}

                {!previewData.previewUrl && !previewData.loading && (
                    <div className="dynamic-pdf__empty-pdf" onClick={startRender}>
                        <div className="dynamic-pdf__empty-pdf__button">
                            <Button variant="contained" color="primary">
                                Render pdf preview
                            </Button>
                        </div>
                    </div>
                )}
            </div>

            {previewData.previewUrl && (
                <div className="dynamic-pdf__options">
                    <Tooltip title="Render new image">
                        <div className="dynamic-pdf__options__button" onClick={startRender}>
                            <UpdateIcon />
                        </div>
                    </Tooltip>
                    <Tooltip title="Download image">
                        <div className="dynamic-pdf__options__button" onClick={onDownload}>
                            <GetAppIcon />
                        </div>
                    </Tooltip>
                </div>
            )}

            {previewData && previewData.loading && (
                <div className="dynamic-pdf__loading-container">
                    <div className="dynamic-pdf__loading-container__text">
                        {(() => {
                            if (renderStatus === 0) {
                                return 'Waiting in queue';
                            }

                            if (renderStatus === 1) {
                                return 'Rendering new PDF. This may take a few moments.';
                            }

                            return 'Loading...';
                        })()}
                    </div>
                    {renderStatus > 0 && (
                        <div className="dynamic-pdf__loading-container__spinner">
                            <CircularProgress />
                        </div>
                    )}
                </div>
            )}

            {(!previewData.previewUrl || previewData.loading) && template.image && (
                <div className="dynamic-pdf__placeholder">
                    <div className="dynamic-pdf__placeholder__image" style={{ backgroundImage: 'url(' + template.image + ')' }}></div>
                </div>
            )}
        </div>
    );
};

DynamicPDF.defaultProps = {
    width: '100%',
    height: 'auto',
    style: {},
    format: 'a4',
    onMutation: () => {}
};

export default DynamicPDF;
