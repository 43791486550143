import React, { useState } from 'react';
import Button from 'components/ui-components-v2/Button';
import Translation from 'components/data/Translation';
import EditorData from 'components/editor-data/EditorData';
import Illustration from 'components/ui-components-cape/Illustration';
import EmptyState from 'components/ui-components-cape/EmptyState';
import LandingpagePreviewRender from './render';
import LandingpagePreviewViewSwitch from './view-switch';
import LandingpageEditor from '../../LandingpageEditor';
import '../styles/main.scss';

/**
 * Display the landing page preview block
 * @param {*} props
 * @returns
 */
const LandingpagePreview = (props) => {
    const { value, model, dataModel, settingsModel, sourceData, canEdit, editorData } = props;
    const [editorOpened, setEditorOpened] = useState(false);
    const [viewWidth, setViewWidth] = useState(1250);

    return (
        <React.Fragment>
            {editorOpened && (
                <LandingpageEditor
                    additionalProps={{ onClose: () => setEditorOpened(false), sourceData }}
                    data={{ ...editorData, model, dataModel, settingsModel }}
                />
            )}
            {!editorOpened && (
                <div className="landingpage-preview">
                    {value && value.length > 0 ? (
                        <React.Fragment>
                            <div className="landingpage-preview__actions">
                                <LandingpagePreviewViewSwitch viewWidth={viewWidth} onSetViewWidth={setViewWidth} />
                                {canEdit && (
                                    <Button onClick={() => setEditorOpened(true)} color="primary" variant="contained">
                                        {Translation.get('visualStackEditor.title.landingpage', 'editor')}
                                    </Button>
                                )}
                            </div>
                            <LandingpagePreviewRender
                                value={value}
                                model={model}
                                dataModel={dataModel}
                                settingsModel={settingsModel}
                                sourceData={sourceData}
                                iFrameUrl={editorData.previewUrl}
                                iFrameWidth={viewWidth}
                                language={EditorData.getLanguage()}
                                key="lpr-preview"
                            />
                        </React.Fragment>
                    ) : (
                        <EmptyState
                            className="landingpage-preview__empty-state"
                            illustration={<Illustration illustration="fallback" />}
                            primaryText={Translation.get('visualStackEditor.placeholder.title', 'editor')}
                            secondaryText={Translation.get('visualStackEditor.placeholder.subtitle', 'editor')}
                            primaryButton={{
                                label: Translation.get('visualStackEditor.placeholder.actionCreate', 'editor'),
                                onClick: () => setEditorOpened(true)
                            }}
                        />
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default LandingpagePreview;
